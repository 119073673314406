import React from 'react';
import pic from '../../assets/Events.jpg';

const EventsPic = () => {
  return (
    <section className="w-[100vw] h-[100vh] flex justify-center items-center ">
      <div className="relative">
        <img
          src={pic}
          alt="pic"
          className="object-cover h-[100vh] w-[100vw] mt-5"
        />

        <div className="absolute text-white hidden md:flex  justify-center items-center opacity-90 top-10 left-0 right-0 bottom-0 m-auto">
          <h1 className="lg:px-10 lg:py-7 px-6 py-4 bg-black text-white lg:text-6xl text-4xl rounded-2xl mb-24 font-poppins">
            Events
          </h1>
        </div>
        <div className="absolute text-3xl  md:hidden text-white opacity-90 inset-0 flex justify-center items-center">
          <h1 className="px-6 py-4 bg-black text-white text-4xl md:text-6xl lg:text-7xl rounded-2xl font-poppins">
            Events
          </h1>
        </div>
      </div>
    </section>
  );
};

export default EventsPic;
